/*@font-face {*/
/*  font-family: "myfont";*/
/*  src: url(./assets/fonts/IRANSans/IRANSansWeb\(FaNum\).eot);*/
/*  src: url(./assets/fonts/IRANSans/IRANSansWeb\(FaNum\)_Light.eot) format("embedded-opentype"),*/
/*  url(./assets/fonts/IRANSans/IRANSansWeb\(FaNum\).woff) format("woff"),*/
/*  url(./assets/fonts/IRANSans/IRANSansWeb\(FaNum\).ttf) format("truetype");*/
/*  font-weight: 100;*/
/*  font-style: normal;*/
/*  unicode-range: U+0600-U+06FF; !* For Persian character range *!*/
/*}*/

@font-face {
  font-family: "myfont2";
  src: url(./assets/fonts/fa-dana/eot/dana-fanum-regular.eot) format("embedded-opentype"),
  url(./assets/fonts/fa-dana/woff/dana-fanum-regular.woff) format("woff"),
  url(./assets/fonts/fa-dana/woff2/dana-fanum-regular.woff2) format("woff2");
  font-weight: 400;
  font-style: normal;
  unicode-range: U+0600-U+06FF; /* For Persian character range */
}

@font-face {
  font-family: "myfont2";
  src: url(./assets/fonts/fa-dana/eot/dana-fanum-bold.eot) format("embedded-opentype"),
  url(./assets/fonts/fa-dana/woff/dana-fanum-bold.woff) format("woff"),
  url(./assets/fonts/fa-dana/woff2/dana-fanum-bold.woff2) format("woff2");
  font-weight: bold;
  font-style: normal;
  unicode-range: U+0600-U+06FF; /* For Persian character range */
}

@font-face {
  font-family: "myfont2";
  src: url(./assets/fonts/fa-dana/eot/dana-fanum-extrabold.eot) format("embedded-opentype"),
  url(./assets/fonts/fa-dana/woff/dana-fanum-extrabold.woff) format("woff"),
  url(./assets/fonts/fa-dana/woff2/dana-fanum-extrabold.woff2) format("woff2");
  font-weight: 800;
  font-style: normal;
  unicode-range: U+0600-U+06FF; /* For Persian character range */
}

@font-face {
  font-family: "myfont2";
  src: url(./assets/fonts/fa-dana/eot/dana-fanum-black.eot) format("embedded-opentype"),
  url(./assets/fonts/fa-dana/woff/dana-fanum-black.woff) format("woff"),
  url(./assets/fonts/fa-dana/woff2/dana-fanum-black.woff2) format("woff2");
  font-weight: 900;
  font-style: normal;
  unicode-range: U+0600-U+06FF; /* For Persian character range */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 16px;
  line-height: 1.5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
  font-family: "myfont2" !important;
}

*:not(.quill, .ql-editor, .ql-editor *, .quill *, .quill-container){
  direction: rtl !important;
}

.quill-container{
  direction: ltr;
}

.custom-player *{
  direction: ltr !important;
}

.container{
  max-width: 1140px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.ant-pagination{
  direction: ltr !important;
}

.ltr *{
  direction: ltr !important;
}

@media only screen and (max-width: 768px) {
  html {
    font-size: 14px;
  }
}

@media only screen and (max-width: 570px) {
  .dynamic-font-16{
    font-size: 12px !important;
  }
  html {
    font-size: 12px;
  }
}

@media only screen and (max-width: 320px) {
  html {
    font-size: 8px;
  }
}

@media only screen and (max-width: 320px) {
  .dynamic-font-16{
    font-size: 8px !important;
  }
}

.hover-cursor-pointer:hover{
  cursor: pointer;
}

.blink {
  animation: blinker 1.5s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0.5;
  }
}

.overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.swiper {
  width: 100%;
  padding: 5px !important;
  padding-bottom: 14px !important;
  margin: 20px auto;
}

.swiper-button-next,.swiper-button-prev{
  width: 40px !important;
  height: 40px !important;
  border-radius: 120px !important;
  box-shadow: 0px 0px 10px 2px rgba(48,48,48,0.9) !important;
  color: rgba(93,93,93,0.73) !important;
  background-color: white;
}

.swiper-button-next:hover,.swiper-button-prev:hover{
  color: rgba(93,93,93,1) !important;
}

.swiper-button-next:after,.swiper-button-prev:after{
  font-size: 12px !important;
}

.notification-messages{
  white-space: pre-line;
}

.ql-editor{
  text-align: right;
}

.admin-course-table .ant-table-cell-row-hover {
  background-color: rgba(95, 200, 215, 0.35) !important;
}

.ant-checkbox-wrapper * {
  direction: ltr !important;
}

.online-status {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #4CAF50; /* Green color */
  border-radius: 50%; /* Makes the div a circle */
  margin-right: 5px; /* Optional spacing */
}

.offline-status {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #f30000;
  border-radius: 50%;
  margin-right: 5px;
}